import { CCol, CRow } from "@coreui/react";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Form, Modal } from "react-bootstrap";
import { IoIosSend } from "react-icons/io";
import { TiDeleteOutline } from "react-icons/ti";
import moment from "moment";
import axios from "../../../axios";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import notification from "../api/notification";
export default function ApplicationPageRemark(props) {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(false);
  // const [remark, setRemark] = useState(props.data.evaluation_remark);
  const [remark, setRemark] = useState([]);
  const [newRemark, setNewRemark] = useState([]);
  // const [remark, setRemark] = useState(props.data.application_remark);
  const [text, setText] = useState("");
  const [scroll, setScroll] = useState(false);
  const handleClose = async () => {
    //create notification api call
    if (newRemark.length) {
      notification
        .createNotification({
          application: props.data.id,
          remarkCount: newRemark.length,
          tag: [],
          url: `http://localhost:3000/home/pages/see-evaluations?id=652d0157ec05b805ccabdea3&can=652d0157ec05b805ccabdea0&jd=652cff96be10cc35d86f43b4`,
          for: "remark",
        })
        .then((res) => {
          setNewRemark([]);
        });
    }
    setShow(false);
  };
  const handleShow = () => {
    // if(props.notification){
    //   axios.patch(`${process.env.REACT_APP_API_BASE_URL}/read-notification/`+props.notification.id,{}, {
    //     headers: { authorization: localStorage.getItem("token") },
    //   }).then((res)=>{
    //     props.setNotification(null)
    //   })
    // }
    setShow(true)
  };

  const messagesEndRef = useRef(null);
  useEffect(() => {
    setRemark(props.data.applicationRemark.remarks);
    //   if(props.data.application_remark.length>0){
    //     setColor(true)
    //   }else{
    //     setColor(false)
    //   }
  }, [props.data.applicationRemark.remarks]);
  // useEffect(() => {
  //   axios
  //     .get(
  //       `${process.env.REACT_APP_API_BASE_URL}/jobapplication/getApplicationRemark`,
  //       {
  //         headers: {
  //           authorization: localStorage.getItem("token"),
  //           application_id: props.data.id,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       setRemark(res.data.remarks);
  //     });
  // }, []);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    console.log(props, "this");
    scrollToBottom();
  });
  const deleteRemark = (target) => {
    let editedRemark = remark.filter((obj, index) => {
      if (index !== target) {
        return obj;
      }
    });

    setRemark(editedRemark);
    scrollToBottom();
    setScroll(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/deleteApplicationRemark`,
        {
          remark: editedRemark,
          id: props.data.id,
          user: localStorage.getItem("id"),
        },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setRemark(response.data.applicationRemark.remarks);
        //   props.ap.setApplications((arr)=>{
        //     return arr.filter((obj)=>{
        //         if(obj.id==props.data.id){
        //             obj.applicationRemark.remarks=response.data
        //             return obj
        //         }else{
        //             return obj
        //         }
        //     })
        //   })
        setText("");
        // handleClose();
      });
  };
  function addRemark(newRemark) {
    scrollToBottom();
    setScroll(false);
    setNewRemark((obj) => {
      return [...obj, newRemark];
    });
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/addApplicationRemark`,
        {
          remark: [...remark, newRemark],
          id: props.data.id,
          user: localStorage.getItem("id"),
        },
        {
          headers: { authorization: localStorage.getItem("token") },
        }
      )
      .then((response) => {
        setRemark(response.data.applicationRemark.remarks);

        //   props.ap.setApplications((arr)=>{
        //     return arr.filter((obj)=>{
        //         if(obj.id==props.data.id){
        //             obj.applicationRemark.remarks=response.data
        //             return obj
        //         }else{
        //             return obj
        //         }
        //     })
        //   })
        setText("");
        // handleClose();
      });
  }
  return (
    <div key={props.data.id}>
      <svg
        onClick={() => handleShow()}
        style={{
          cursor: "pointer",
          color: remark ? (remark[0] ? "#ef233c" : "#8d99ae") : "#8d99ae",
        }}
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        
        fill="currentColor"
        // className={remark[0] ? "bi text-primary " : "bi bi-card-text"}
        viewBox="0 0 16 16"
      >
        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
        <path d="M3 5.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 8zm0 2.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z" />
      </svg>
      
      {props?.notification?.length > 0 && <Badge className="text-white bg-danger ml-1">{props.notification.length}</Badge>}
      {props?.notification?.remarkCount > 0 && <Badge className="text-white bg-danger ml-1">{props.notification.remarkCount}</Badge>}
      <Modal centered backdrop="static" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex">
              <div>
                Remark for{" "}
                <span className="text-primary">
                  <Link
                    to={`/home/pages/detailprofile?id=${props.data.candidate.id}`}
                  >
                    {props.data.name}'s
                  </Link>
                </span>{" "}
                Application
              </div>
              {/* <div className="mx-1 text-primary">
                  {props.data.can_id.name}'s
                </div>
                <div>Evalution</div> */}
            </div>
            <div className="d-flex">
              <div>
                {props.data.designation}-
                <span className="text-primary">
                  <Link to={`/home/pages/JdProfile?id=${props.data.jd_id.id}`}>
                    {props.data.custome_jd_id}
                  </Link>
                </span>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="w-100  flex-column rounded p-1 mb-2"
            style={{
              height: "auto",
              maxHeight: "300px",
              backgroundColor: "#ffffff",
              overflowY: "scroll",
              display: "inline-block",
            }}
          >
            {remark &&
              remark.map((obj, index) => {
                const text = obj.remark;
                const replacedText = text.replace(
                  /(https?:\/\/[^\s]+)/g,
                  '<a href="$1" target="_blank">$1</a>'
                );
                let str2 = "";
                if (obj.role) {
                  const str = obj.role;
                  str2 = str.charAt(0).toUpperCase() + str.slice(1);
                }

                return (
                  <div className="w-100 d-flex">
                    <div
                      className={
                        localStorage.getItem("id") === obj.userId
                          ? "mb-2 px-1 rounded ml-auto"
                          : "mb-2 px-1 rounded"
                      }
                      style={{
                        backgroundColor:
                          localStorage.getItem("id") === obj.userId
                            ? "#e9f5db"
                            : "#dee2ff",
                        minWidth: "70%",

                        maxWidth: "80%",
                      }}
                      key={index}
                    >
                      <div className=" d-flex justify-content-between align-items-center">
                        <small className=" text-primary ">
                          {obj.user}-{str2}
                        </small>

                        {localStorage.getItem("role") === "admin" ? (
                          <TiDeleteOutline
                            className="cross"
                            onClick={() => {
                              deleteRemark(index);
                            }}
                            style={{ cursor: "pointer" }}
                            size={19}
                          ></TiDeleteOutline>
                        ) : (
                          <></>
                        )}
                        {/* : localStorage.getItem("id") === obj.userId ? (
                            <TiDeleteOutline
                              className="cross"
                              onClick={() => {
                                deleteRemark(index);
                              }}
                              style={{ cursor: "pointer" }}
                              size={19}
                            ></TiDeleteOutline>
                          )  */}
                      </div>
                      <p className=" mb-0 mt-0 pl-1 pt-0 ">
                        <small
                          className="mt-0 pl-1 pt-0"
                          style={{ color: "#231942" }}
                          dangerouslySetInnerHTML={{ __html: replacedText }}
                        >
                          {/* {obj.remark} */}
                        </small>
                      </p>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "auto",
                        }}
                      >
                        <small
                          style={{ fontSize: ".7rem" }}
                          className="mt-0 text-end ml-auto"
                        >
                          {moment(obj.date).startOf().fromNow()}
                        </small>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div
              style={{ width: "100%", height: "2px" }}
              ref={messagesEndRef}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex ">
          <CRow className={" w-100"}>
            <CCol className={"col-10 col-sm-10 p-0 "}>
              <Form.Control
                value={text}
                onChange={(e) => {
                  setText(e.target.value.trimStart());
                }}
                as="textarea"
                rows={1}
              />
            </CCol>
            <CCol className={"col-sm-1"}>
              <Button
                disabled={text ? false : true}
                variant="primary"
                onClick={(e) => {
                  setScroll(true);
                  let date = Date.now();
                  // if (remark[0]) {
                  //   setRemark((obj) => {
                  //     return [
                  //       ...obj,
                  //       {
                  //         date: date,
                  //         remark: text,
                  //         user: localStorage.getItem("id"),
                  //         userId: localStorage.getItem("id"),
                  //         role: localStorage.getItem("role"),
                  //       },
                  //     ];
                  //   });
                  // } else {
                  //   setRemark([
                  //     {
                  //       date: date,
                  //       remark: text,
                  //       user: localStorage.getItem("id"),
                  //       userId: localStorage.getItem("id"),
                  //       role: localStorage.getItem("role"),
                  //     },
                  //   ]);
                  // }

                  addRemark({
                    date: date,
                    remark: text,
                    user: localStorage.getItem("id"),
                    userId: localStorage.getItem("id"),
                    role: localStorage.getItem("role"),
                  });
                }}
              >
                <IoIosSend size={22}></IoIosSend>
              </Button>
            </CCol>
          </CRow>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
