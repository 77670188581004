import { CCol, CContainer, CInput, CLabel, CRow } from "@coreui/react";
import { Container } from "react-bootstrap";
// import "./styles.css";
import img from "./No openings.png";
import { Outlet, Route, Switch } from "react-router-dom";
import Opening from "./Openings";
import { createContext, useContext, useEffect, useState } from "react";
import { jdContext } from "src/UserContext";
import axios from "axios";
import { ApplyMobile } from "./ApplyMobile";
import Loading from "../candidates/componants/Loading";
import sideImage from "./sideBannerImg.jpg";
import moment from 'moment'
import {
  BsFacebook,
  BsGoogle,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsWhatsapp,
} from "react-icons/bs";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export const ApplyJob = (props) => {
  var now = new Date();
  var yesterday = new Date();
  yesterday.setDate(now.getDate());
  const [page, setPage] = useState(true);
  const [jdId, setJdId] = useState("");
  const [C_JD_ID, setC_JD_ID] = useState("");
  const [empty, setEmpty] = useState(false);
  const [load, setLoad] = useState(true);
  const [desig, setDesig] = useState("");
  let history = useHistory();
  
  useEffect(() => {
    // console.log(props.location.state);
    if (!props.location.state) {
      history.push("/");
    }
  });
  useEffect(() => {
    setJdId("");
    setC_JD_ID("");
  }, []);
 
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getalljobs`, {
        headers: {
          authorization: localStorage.getItem("token"),
          location: "login",
        },
      })
      .then((res) => {
        if (res) {
          if (res.data.token) {
            localStorage.setItem("token", "token");
            window.location.reload();
          } else {
            // setData(res.data);
            let newData = res.data.filter((obj) => {
              // if (obj.jdStatus != "Closed") {
              //   if (obj.close_date >= yesterday) {
              //     return obj;
              //   }
              // }
              if (obj.jdStatus != "Closed" && obj.visibility == "Live") {
                var a = moment(obj.close_date);
                var b = moment().utc();
                var d = a.diff(b, "days");
                if (d > 0) {
                  //  console.log(obj.title, "greater" , moment(obj.close_date).format("DD MMM YYYY"));
                  return obj;
                } else if (d < 0) {
                  //  console.log(obj.title, "smaller" , moment(obj.close_date).format("DD MMM YYYY"));
                } else {
                  //  console.log(obj.title, "equal"  , moment(obj.close_date).format("DD MMM YYYY"));
                  return obj;
                }
              }
              // if (obj.close_date >= yesterday) {
              //   return obj
              // }
            });
            setEmpty(!newData[0] ? false : true);
            setLoad(false);
          }
        }
      });
  }, []);
  const [showModal, setShowModal] = useState(
    window.innerWidth < 767 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return load ? (
    <Loading></Loading>
  ) : empty ? (
    <CRow className={"w-100 m-0 "} style={{ height: "100vh" }}>
      <CCol
        md="3"
        style={{ backgroundColor: "#0f77ea" }}
        className={"vh-100 d-none d-md-block"}
      >
        <div className="w-100 d-flex justify-content-center mt-5">
          <img
            style={{ width: "75%" }}
            className="mt-5 mx-auto"
            src={sideImage}
          ></img>
        </div>
      </CCol>
      <jdContext.Provider
        value={{ jdId, setJdId, C_JD_ID, setC_JD_ID, desig, setDesig }}
      >
        <CCol
          sm="12"
          md="9"
          style={{
            maxHeight: "100vh",
            backgroundImage:
              "url('https://img.freepik.com/free-vector/gradient-white-color-background-abstract-modern_343694-2125.jpg?w=826&t=st=1684468861~exp=1684469461~hmac=d1e24749c7d185ec959c5b491fee5c4a2029209be37c608653d5f1cfda4e7196')",
            backgroundSize: "cover",
          }}
          className={" text-dark"}
        >
          {/* <Apply></Apply> */}

          <Switch>
            <Route exact path="/openings" component={Opening} />
          </Switch>
          <Switch>
            <Route path="/openings/apply" component={ApplyMobile} />
          </Switch>
          {/* <Opening></Opening> */}
        </CCol>
      </jdContext.Provider>
    </CRow>
  ) : (
    <CRow
      style={{
        width: "100%",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
      className={"bg-white d-flex  justify-content-center mx-auto "}
    >
      <CCol
        style={{ height: "auto", backgroundColor: "#F5F9FF" }}
        xs="12"
        md="3"
        className={"text-dark  order-md-1 order-2"}
      >
        <CCol xs="12" className={"text-center py-3 "}>
          <div style={{ width: "35%" }} className="mx-auto">
            <img
              style={{ width: "100%" }}
              src="https://res.cloudinary.com/dcytixl43/image/upload/v1679634232/mobigic_logo_cqhvfe.svg"
              alt=""
              className="mx-1"
            />
          </div>
          <h5
            className=" logoName mt-1 text-center my-1"
            style={{ color: "#0f77ea", fontFamily: "Nunito Sans" }}
          >
            Mobigic® Technologies Private Limited.
          </h5>
          <p className="my-1">Where Business meets Technology.... </p>
        </CCol>
        {/* <CCol xs="12">
          <h5 className="text-center">A BRIEF ABOUT US.</h5>
          <small>
            Mobigic® Technologies Private Limited is established as a Technology
            Company operating in India, to pursue business through innovation by
            utilizing advanced and latest technologies. We are a #startupindia
            registered organization working in digital transformation journey of
            various industry verticals. DIPP # 19551 MSME registered initiative
            approved by the Government of India. MSME # MH33D0023015
          </small>
        </CCol> */}
        <CCol xs="12">
          <h5>Contact</h5>
          <p className="my-0 text-primary">hr@mobigic.com</p>
          <p className="my-0 text-primary">info@mobigic.com</p>
          <a target="_blank" href="https://www.mobigic.com">
            www.mobigic.com
          </a>
        </CCol>
        <CCol xs="12">
          <h5 className="mt-3">Pune Office:</h5>
          <small style={{ textAlign: "justify", textJustify: "inter-word" }}>
            Office No. 5, 3'rd floor,Urban Bliss, Samarth Colony, Opp Krushna
            Medical, PAN Card Club Rd, Baner, Pune, Maharashtra 411045
          </small>
        </CCol>
        <CCol xs="12">
          <h5 className="mt-3">Thane Office:</h5>
          <small style={{ textAlign: "justify", textJustify: "inter-word" }}>
            Tower B, Lodha Supremus,1104, 11th Floor, Kolshet Rd,near Air Force
            Station,Thane, Maharashtra 400607
          </small>
        </CCol>
        <CCol xs="12">
          <div className="d-flex w-50 justify-content-between mx-auto mt-4">
            <a
              href="https://www.linkedin.com/in/mobigic-technologies-private-limited-25a5151a0/"
              target="_blank"
            >
              <BsLinkedin size={"24"}></BsLinkedin>
            </a>
            <a href="https://www.facebook.com/mobigic" target="_blank">
              <BsFacebook size={"24"}></BsFacebook>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=%2B917977040526&data_filter_required=ARDhC3rlfR4u3_NvyBIS05VNezWF89HeKIo6w1aAeXiAFRNuBM3xef1KfaaWUtFMovidQ1k0z4v_4MI-Y5c7lKsdLg-4rOF0ECmCya1CW00rMIzaALd-enbQBQFhyPIC9Ux-G5qL32T3dY0lDIypzT_1uQ&source=FB_Page&app=facebook&entry_point=page_cta&fbclid=IwAR2EPKMolBB-PLwzsNn4bJpVNtddK5YtJgPWWAiXg0dMiEYwOcNiMEnSRX0"
              target="_blank"
            >
              <BsWhatsapp size={"24"}></BsWhatsapp>
            </a>
            <a href="https://twitter.com/mobigic_india" target="_blank">
              <BsTwitter size={"24"}></BsTwitter>
            </a>
          </div>
          <p style={{ fontSize: "11px" }} className="text-center mt-2">
            Copyrights © 2020 All Rights Reserved by Mobigic® Technologies Pvt.
            Ltd.
          </p>
        </CCol>
      </CCol>
      <CCol
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
        xs="12"
        md="9"
        className={"order-md-2 order-1"}
      >
        <div className="d-flex  justify-content-center align-items-center p-3  w-100">
          {/* <img
            style={{ width: "5%" }}
            src="https://res.cloudinary.com/dcytixl43/image/upload/v1679634232/mobigic_logo_cqhvfe.svg"
            alt=""
            className="mx-1"
          /> */}
          <h4
            style={{ textAlign: "center" }}
            className="logoName d-flex align-items-center mt-1 mx-1"
          >
            <h4
              className=" logoName mt-1"
              style={{ color: "#0f77ea", fontFamily: "Nunito Sans" }}
            >
              Welcome to Mobigic® Technologies Private Limited.
            </h4>
          </h4>
        </div>
        <CCol
          style={{
            display: "flex",
            justifyContent: "center",
            height: "80%",
          }}
          className={" w-md-50 w-100 mx-auto "}
        >
          <img
            // style={{ height: "90%", width: "" }}
            style={{ width: "100%", objectFit: "contain" }}
            className=""
            src={img}
          ></img>
          {/* <div
            style={{
              height:"100%",
              width:"auto",
              backgroundImage:
                "url('https://res.cloudinary.com/dcytixl43/image/upload/v1683717375/No_openings_pwyesd.png')",
              backgroundSize: "auto 100%",
              backgroundRepeat: "no-repeat",
             
            }}
            className="mx-auto bg-danger"
          ></div> */}
        </CCol>
      </CCol>
    </CRow>
  );
};
