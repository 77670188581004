import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CInput,
  CLabel,
  CRow,
} from "@coreui/react";
import "./styles.css";
import { Button, Card, Modal } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { useHistory } from "react-router-dom";
import { MdDateRange, MdLocationOn, MdWork } from "react-icons/md";
import { IoLocation, IoTodaySharp } from "react-icons/io5";
import { FaHeadSideVirus } from "react-icons/fa";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { jdContext } from "src/UserContext";
import Loading from "../candidates/componants/Loading";
import { IoMdCloseCircle } from "react-icons/io";
import logo from "./mobigic_sticker_path (1).svg";
import { Apply } from "./Apply";
import { AiFillLeftCircle } from "react-icons/ai";
import { BsArrowLeftShort } from "react-icons/bs";

export default function Opening() {
  const elRefs = useRef([]);
  const jobCardScroll = useRef(null);
  var now = new Date();
  var yesterday = new Date();
  yesterday.setDate(now.getDate());
  var now = moment();

  let history = useHistory();
  const [data, setData] = useState([]);
  const [viewJd, setViewJd] = useState([]);
  const [opened, setOpened] = useState();
  const [load, setLoad] = useState(true);
  const [view, setView] = useState("12");
  const [cardWidth, setCardWidth] = useState("");
  const [cardDisplay, SetCardDisplay] = useState("grid");
  const [width, setWidth] = useState();
  const [apply, setApply] = useState(true);
  const [jobCard, setJobCard] = useState();
  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getalljobs`, {
        headers: {
          authorization: localStorage.getItem("token"),
          location: "login",
        },
      })
      .then((res) => {
        if (res) {
          if (res.data.token) {
            localStorage.setItem("token", "token");
            window.location.reload();
          } else {
            let newData = res.data.filter((obj) => {
              //old code
              // if (obj.close_date >= yesterday) {
              //   return obj;
              // }
              if (obj.jdStatus != "Closed" && obj.visibility == "Live") {
                var a = moment(obj.close_date);
                var b = moment().utc();
                var d = a.diff(b, "days");
                if (d > 0) {
                  //  console.log(obj.title, "greater" , moment(obj.close_date).format("DD MMM YYYY"));
                  return obj;
                } else if (d < 0) {
                  //  console.log(obj.title, "smaller" , moment(obj.close_date).format("DD MMM YYYY"));
                } else {
                  //  console.log(obj.title, "equal"  , moment(obj.close_date).format("DD MMM YYYY"));
                  return obj;
                }
              }
            });
            setData(newData);
            // if (newData.length == 1) {
            //   setViewJd([newData[0]]);
            // }
            setLoad(false);
          }
        }
      });
  }, []);
  const [showModal, setShowModal] = useState(
    window.innerWidth < 767 ? true : false
  );
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 767) {
        setShowModal(true);
      } else {
        setShowModal(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let { jdId, setJdId, C_JD_ID, setC_JD_ID, desig, setDesig } =
    useContext(jdContext);
  const [offcanvas, setOffcanvas] = useState(false);
  function openOffcanvas() {
    setOffcanvas(false);
  }
  return load ? (
    <Loading></Loading>
  ) : (
    // data.length != 1 ?
    <CContainer
      style={{ minHeight: "100vh", maxHeight: "auto" }}
      className={showModal ? "info_details px-0" : "info_details"}
    >
      <div className="d-flex  justify-content-center align-items-center p-3 w-100">
        <img
          style={{ width: showModal ? "50%" : "23%", cursor: "pointer" }}
          src={logo}
          alt=""
          className="mx-1"
          onClick={() => {
            history.push("/");
          }}
        />
        {/* <h4
          style={{ textAlign: "center" }}
          className="logoName d-flex align-items-center mt-1 mx-1"
        >
          {!showModal ? (
            <h4 className=" logoName mt-1" style={{ color: "#0f77ea" }}>
              Mobigic Technologies Pvt Ltd.
            </h4>
          ) : (
            <h5 className=" logoName mt-1" style={{ color: "#0f77ea" }}>
              Mobigic Technologies Pvt Ltd.
            </h5>
          )}
        </h4> */}
      </div>
      <hr className="my-1"></hr>
      {data[0] && (
        <CRow
          className={"mx-auto pt-2   rounded-top"}
          style={{ width: "95%" }}
          // style={{ width: "95%",background:" rgba(255,255,255,0.8)" }}
        >
          {" "}
          {apply ? (
            <p className="my-0    ml-md-3 h4 pt-1 bold  rounded-top">
              {data.length} Current Openings
            </p>
          ) : (
            <p
              onClick={() => {
                setApply(true);
              }}
              className="my-0    ml-md-3  pt-0 pb-0   rounded-top"
              style={{ cursor: "pointer" }}
            >
              <BsArrowLeftShort
                style={{ cursor: "pointer" }}
                className="p-0"
                size={35}
              ></BsArrowLeftShort>{" "}
              Current openings
            </p>
          )}
        </CRow>
      )}
      {data[0] && (
        <CRow
          className={"mx-auto pt-2 px-md-0 px-2 rounded-bottom "}
          style={{ width: "95%", maxHeight: "80%" }}
        >
          {apply && (
            <CCol
              md={view}
              xs="12"
              className={showModal ? `px-0 ` : ``}
              style={{
                maxHeight: "73vh",
                overflowY: "scroll",
                display: width < 721 ? "" : cardDisplay,
                gridTemplateColumns: "1fr 1fr 1fr",
                columnGap: "10px",
                rowGap: "10px",
              }}
            >
              {data.map((obj, index) => {
                // .close_date >= yesterday
                if (obj) {
                  return (
                    <CCard
                      key={index}
                      id={obj.id}
                      style={{
                        cursor: "pointer",
                        borderRadius: "10px",
                        // backgroundColor: opened === index ? "#e7ecef" : "",
                        border: opened === index ? "3px solid #0f77ea" : "",
                        color: "black",
                      }}
                      className={
                        opened === index
                          ? `jobCard  mb-1  shadow-sm ${cardWidth}`
                          : `jobCard mb-1  ${cardWidth} `
                      }
                      onClick={() => {
                        setViewJd([obj]);
                        setOpened(index);
                        setOffcanvas(true);
                        setView("5");
                        setCardWidth("col-md-12");
                        setJobCard("d-none d-md-block");
                        SetCardDisplay("");
                        const element = document.getElementById(obj.id);
                        let id = document.getElementById("jobCardScroll");
                        // element.scrollIntoView({behavior:"smooth"});
                        for (let i = 0; i < 2; i++) {
                          if (i == 1) {
                            if (width > 721) {
                              setTimeout(() => {
                                element.scrollIntoView({ behavior: "smooth" });
                              }, 800);
                            }
                          } else {
                            setTimeout(() => {
                              if (viewJd[0]) {
                                id.scrollIntoView({ behavior: "auto" });
                              }
                            }, 800);
                          }
                        }
                      }}
                    >
                      {" "}
                      <CCardBody className={"pt-3"}>
                        <h5
                          title="Job title"
                          style={{
                            color: opened === index ? "#0f77ea" : "#0f77ea",
                          }}
                        >
                          {obj.title}
                        </h5>
                        <p
                          className=" m-0 d-flex align-items-center"
                          title="Work location"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-geo-alt-fill mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                          </svg>

                          {obj.location}
                        </p>
                        <p
                          className=" m-0 d-flex align-items-center"
                          title="Work type"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-briefcase-fill mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z" />
                            <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z" />
                          </svg>
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-person-workspace mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                            <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z" />
                          </svg> */}
                          {obj.type}
                        </p>

                        <p
                          className=" m-0 d-flex align-items-center"
                          title="Experience"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-calendar-check-fill m-0 p-0 mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-5.146-5.146-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                          </svg>
                          {obj.min_exp == "0" && obj.max_exp == "0" ? (
                            <>Fresher</>
                          ) : (
                            <>
                              {obj.min_exp}-{obj.max_exp} Years
                            </>
                          )}
                        </p>

                        <p
                          className="m-0 d-flex align-items-center"
                          title="Posted"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-calendar-event-fill mr-2"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-3.5-7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z" />
                          </svg>
                          <span>
                            {moment(obj.createdAt).startOf().fromNow()}
                          </span>
                        </p>
                      </CCardBody>
                    </CCard>
                  );
                }
              })}
            </CCol>
          )}
          {showModal && (
            <CCol className={"d-block d-md-none"}>
              <Modal
                centered
                className={"d-block d-md-none"}
                show={offcanvas}
                onHide={() => {
                  setOffcanvas();
                }}
              >
                <Modal.Header className="py-1 text-end">
                  <IoMdCloseCircle
                    className="ml-auto text-secondary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setOffcanvas(false);
                    }}
                    size={30}
                  ></IoMdCloseCircle>
                </Modal.Header>
                {viewJd[0] && (
                  <CCard
                    className={""}
                    sm="12"
                    style={{
                      height: "100%",
                      maxHeight: "100%",
                      color: "black",
                    }}
                  >
                    <CCardHeader
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                      className={"bg-white d-flex pb-0 justify-content-between"}
                    >
                      <p className="h5 text-dark" style={{ color: "black" }}>
                        {viewJd[0].title}
                      </p>
                      <div>
                        <Button
                          onClick={() => {
                            setJdId(viewJd[0].id);
                            setC_JD_ID(viewJd[0].custome_jd_id);
                            setDesig(viewJd[0].title);
                            history.push({
                              pathname: "/openings/apply",
                              state: true,
                            });
                          }}
                          className={"btn primary-btn btn-sm font-weight-bold"}
                        >
                          Apply
                        </Button>
                      </div>
                    </CCardHeader>
                    <CCardBody style={{ overflowY: "scroll" }}>
                      <div className="d-flex p-0 m-0">
                        <small
                          className="col-3 m-0 p-0 mt-2"
                          style={{ color: "black" }}
                        >
                          Mandatory skills{" "}
                        </small>
                        <small className="px-0 pt-1 mt-1 p-0 m-0">
                          :&nbsp;
                        </small>
                        <p
                          className="m-0 d-flex col-9 p-0 "
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            alignItems: "flex-start",
                            alignContent: "flex-start",
                            minWidth: "0",
                            flexWrap: "wrap",
                            // overflowY: "scroll",
                          }}
                        >
                          {viewJd[0].skills.map((obj, index) => {
                            if (index == 0) {
                              return (
                                <small
                                  key={index}
                                  className="rounded bg-info text-white px-1 pt-1 ml-0 mr-1 mt-1"
                                >
                                  {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                                  {obj.value}{" "}
                                  {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                                </small>
                              );
                            } else {
                              return (
                                <small
                                  key={index}
                                  className="rounded bg-info text-white px-1 pt-1 ml-0 mr-1 mt-1"
                                >
                                  {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                                  {obj.value}{" "}
                                  {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                                </small>
                              );
                            }
                          })}{" "}
                        </p>
                      </div>
                      {viewJd[0].otherSkills[0] && (
                        <div className="d-flex p-0 m-0">
                          <small
                            className="col-3 m-0 p-0 mt-2"
                            style={{ color: "black" }}
                          >
                            Other Skills{" "}
                          </small>
                          <small className="px-0 pt-1 mt-1 p-0 m-0">
                            :&nbsp;
                          </small>
                          <p
                            className="m-0 d-flex col-9 p-0 "
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              alignItems: "flex-start",
                              alignContent: "flex-start",
                              minWidth: "0",
                              flexWrap: "wrap",
                              // overflowY: "scroll",
                            }}
                          >
                            {viewJd[0].otherSkills.map((obj, index) => {
                              if (index == 0) {
                                return (
                                  <small
                                    key={index}
                                    className="rounded px-0 pt-1 ml-0 mt-1"
                                  >
                                    {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                                    {obj.value}{" "}
                                    {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                                  </small>
                                );
                              } else {
                                return (
                                  <small
                                    key={index}
                                    className="rounded px-0 pt-1 ml-0 mt-1"
                                  >
                                    {", "}
                                    {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                                    {obj.value}{" "}
                                    {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                                  </small>
                                );
                              }
                            })}{" "}
                          </p>
                        </div>
                      )}
                      {viewJd[0].educations[0] && (
                        <div className="d-flex p-0 m-0">
                          <small
                            className="col-3 m-0 p-0 mt-2"
                            style={{ color: "black" }}
                          >
                            Qualification{" "}
                          </small>
                          <small className="px-0 pt-1 mt-1 p-0 m-0">
                            :&nbsp;
                          </small>
                          <p
                            className="m-0 d-flex col-9 p-0 "
                            style={{
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              alignItems: "flex-start",
                              alignContent: "flex-start",
                              minWidth: "0",
                              flexWrap: "wrap",
                              // overflowY: "scroll",
                            }}
                          >
                            {viewJd[0].educations.map((obj, index) => {
                              if (index == 0) {
                                return (
                                  <small
                                    key={index}
                                    className="rounded px-0 pt-1 ml-0 mt-1"
                                  >
                                    {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                                    {obj.value}{" "}
                                    {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                                  </small>
                                );
                              } else {
                                return (
                                  <small
                                    key={index}
                                    className="rounded px-0 pt-1 ml-0 mt-1"
                                  >
                                    {", "}
                                    {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                                    {obj.value}{" "}
                                    {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                                  </small>
                                );
                              }
                            })}{" "}
                          </p>
                        </div>
                      )}
                      <div className="d-flex mt-2">
                        <small
                          className="col-3 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          Experience{" "}
                        </small>
                        {viewJd[0].min_exp == "0" &&
                        viewJd[0].max_exp == "0" ? (
                          <small
                            className="col-9 p-0 m-0"
                            style={{ color: "black" }}
                          >
                            : Fresher
                          </small>
                        ) : (
                          <small
                            className="col-9 p-0 m-0"
                            style={{ color: "black" }}
                          >
                            : {viewJd[0].min_exp}-{viewJd[0].max_exp} Years
                          </small>
                        )}
                      </div>
                      <div className="d-flex mt-2">
                        <small
                          className="col-3 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          No.of Positions{" "}
                        </small>
                        <small
                          className="col-9 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          : {viewJd[0].no_position}
                        </small>
                      </div>
                      <div className="d-flex mt-2">
                        <small
                          className="col-3 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          Location{" "}
                        </small>
                        <small
                          className="col-9 p-0 m-0 "
                          style={{ color: "black" }}
                        >
                          : {viewJd[0].location}
                        </small>
                      </div>
                      <div className="d-flex mt-2">
                        <small
                          className="col-3 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          Type{" "}
                        </small>
                        <small
                          className="col-9 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          : {viewJd[0].type}
                        </small>
                      </div>
                      <div className="d-flex mt-2">
                        <small
                          className="col-3 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          Salary
                        </small>
                        <small
                          className="col-9 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          : {viewJd[0].min_sal}-{viewJd[0].max_sal} CTC (LPA in
                          Rupees)
                        </small>
                      </div>
                      <div className="d-flex mt-2">
                        <small
                          className="col-3 p-0 m-0"
                          style={{ color: "black" }}
                        >
                          Description
                        </small>
                        <small className="px-0  p-0 m-0">:&nbsp;</small>
                        <small
                          className="col-9 p-0 m-0"
                          style={{ color: "black" , whiteSpace:"pre-wrap"}}
                        >
                          {viewJd[0].description}
                        </small>
                      </div>
                      {viewJd[0].responsibilities && (
                        <div className="d-flex mt-2">
                          <small
                            className="col-3 p-0 m-0"
                            style={{ color: "black" ,whiteSpace:"pre-wrap"}}
                          >
                            Responsibilities
                          </small>
                          <small className="px-0  p-0 m-0">:&nbsp;</small>
                          <small
                            className="col-9 p-0 m-0"
                            style={{ color: "black" }}
                          >
                            {viewJd[0].responsibilities}
                          </small>
                        </div>
                      )}
                      {/* <br></br>
                      <p
                        className="font-weight-bold"
                        style={{ color: "black" }}
                      >
                        Description
                      </p>

                      <p style={{ whiteSpace: "pre-wrap", color: "black" }}>
                        {viewJd[0].description}
                      </p>
                      {viewJd[0].responsibilities && (
                        <p
                          className="font-weight-bold"
                          style={{ color: "black" }}
                        >
                          Responsibilities
                        </p>
                      )}

                      {viewJd[0].responsibilities && (
                        <p style={{ whiteSpace: "pre-wrap", color: "black" }}>
                          {viewJd[0].responsibilities}
                        </p>
                      )} */}
                      {/* <p>
                        Closing on :{" "}
                        {moment(viewJd[0].close_dare).format("DD-MMM-YYYY")}
                      </p> */}
                    </CCardBody>
                  </CCard>
                )}
              </Modal>
            </CCol>
          )}
          {viewJd[0] && (
            <CCol
              md={apply ? "7" : "6"}
              className={jobCard}
              style={{ maxHeight: "73vh", minHeight: "73vh" }}
            >
              <CCard
                className={"shadow jobBoxAnimation events"}
                sm="12"
                style={{
                  height: "100%",
                  maxHeight: "100%",
                  borderRadius: "10px",
                }}
              >
                <CCardHeader
                  style={{
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  className={"bg-white d-flex pb-0 justify-content-between"}
                >
                  <p className="h4 " style={{ color: "black" }}>
                    {viewJd[0].title}
                  </p>

                  {apply && (
                    <div>
                      <Button
                        onClick={() => {
                          setJdId(viewJd[0].id);
                          setC_JD_ID(viewJd[0].custome_jd_id);
                          setDesig(viewJd[0].title);
                          // history.push({
                          //   pathname: "/openings/apply",
                          // });
                          setApply(false);
                        }}
                        className={"btn primary-btn btn-sm font-weight-"}
                      >
                        Apply
                      </Button>
                    </div>
                  )}
                </CCardHeader>
                <CCardBody
                  style={{
                    overflowY: "scroll",
                    maxHeight: "100%",
                    color: "black",
                  }}
                  className={"bg-white pt-0"}
                >
                  <div
                    ref={jobCardScroll}
                    className="mt-2 p-2"
                    id="jobCardScroll"
                  ></div>
                  <div className="d-flex  p-0 m-0">
                    <p className="col-3 mt-2 p-0 ">Mandatory skills </p>
                    <p className="px-0 pt-1 mt-1 p-0 m-0">:&nbsp;</p>
                    <p
                      className="m-0 d-flex col-9 p-0 "
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        alignItems: "flex-start",
                        alignContent: "flex-start",
                        minWidth: "0",
                        flexWrap: "wrap",
                        // overflowY: "scroll",
                      }}
                    >
                      {viewJd[0].skills.map((obj, index) => {
                        if (index == 0) {
                          return (
                            <p
                              key={index}
                              // className="p-0 m-0"
                              className="rounded bg-info text-white px-1 pt-1 mr-1 mt-1 p-0 m-0"
                              // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
                            >
                              {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                              {obj.value}{" "}
                              {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                            </p>
                          );
                        } else {
                          return (
                            <p
                              key={index}
                              // className="p-0 m-0"
                              className="rounded bg-info text-white px-1 pt-1 mr-1 mt-1 p-0 m-0"
                              // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
                            >
                              {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                              {obj.value}{" "}
                              {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                            </p>
                          );
                        }
                      })}{" "}
                    </p>
                  </div>
                  {viewJd[0].otherSkills[0] && (
                    <div className="d-flex p-0 m-0">
                      <p className="col-3 mt-2 mb-0 p-0">Other Skills</p>
                      <p className="px-0 pt-1 mt-1 p-0 m-0">:&nbsp;</p>
                      <p
                        className="m-0 d-flex col-9 p-0 "
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          alignItems: "flex-start",
                          alignContent: "flex-start",
                          minWidth: "0",
                          flexWrap: "wrap",
                          // overflowY: "scroll",
                        }}
                      >
                        {viewJd[0].otherSkills.map((obj, index) => {
                          // if (index!=-1) {
                          return (
                            <p
                              key={index}
                              // className="p-0 m-0"
                              className="rounded  px-0 pt-1 ml-0 mt-1 p-0 m-0"
                              // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
                            >
                              {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                              {obj.value}
                              {index < viewJd[0].otherSkills.length - 1 && ", "}
                              &nbsp;
                            </p>
                          );
                          // } else {
                          //   return (
                          //     <p
                          //       key={index}
                          //       // className="p-0 m-0"
                          //       className="rounded px-0 pt-1 ml-0 mt-1 p-0 m-0"
                          //       // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
                          //     >
                          //       {/* {index < viewJd[0].otherSkills.length - 1 && ","} */}

                          //       {/* {", "} */}
                          //       {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                          //       {obj.value}
                          //       {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                          //     </p>
                          //   );
                          // }
                        })}{" "}
                      </p>
                    </div>
                  )}
                  {viewJd[0].educations[0] && (
                    <div className="d-flex mt-2 p-0 m-0">
                      <p className="col-3 mt-2 mb-0 p-0">Qualification</p>
                      <p className="px-0 pt-1 mt-1 p-0 m-0">:&nbsp;</p>
                      <p
                        className="m-0 d-flex col-9 p-0 "
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          alignItems: "flex-start",
                          alignContent: "flex-start",
                          minWidth: "0",
                          flexWrap: "wrap",
                          // overflowY: "scroll",
                        }}
                      >
                        {viewJd[0].educations.map((obj, index) => {
                          // if (index == 0) {
                          return (
                            <p
                              key={index}
                              // className="p-0 m-0"
                              className="rounded  px-0 pt-1 ml-0 mt-1 p-0 m-0"
                              // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
                            >
                              {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                              {obj.value}
                              {index < viewJd[0].educations.length - 1 && ", "}
                              &nbsp;
                            </p>
                          );
                          // } else {
                          //   return (
                          //     <p
                          //       key={index}
                          //       // className="p-0 m-0"
                          //       className="rounded px-0 pt-1 ml-0 mt-1 p-0 m-0"
                          //       // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
                          //     >
                          //       {/* {index < viewJd[0].educations.length - 1 && ","} */}
                          //       {/* {", "} */}
                          //       {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
                          //       {obj.value}
                          //       {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
                          //     </p>
                          //   );
                          // }
                        })}{" "}
                      </p>
                    </div>
                  )}

                  {/* <p className="d-flex">
                    Mandatory skill :&nbsp;{" "}
                    {viewJd[0].skills.map((obj, index) => {
                      return (
                        <p key={index} className="m-0">
                          {obj.value}
                          {""}
                          {index != viewJd[0].skills.length - 1 && ", "}&nbsp;
                        </p>
                      );
                    })}
                  </p> */}
                  {/* <br></br> */}
                  {/* <p>
                    Experience : {viewJd[0].min_exp}-{viewJd[0].max_exp} Years
                  </p> */}

                  <div className="d-flex mt-3">
                    <p className="col-3 p-0 m-0">Experience </p>
                    {viewJd[0].min_exp == "0" && viewJd[0].max_exp == "0" ? (
                      <p className="col-9 p-0 m-0">: Fresher</p>
                    ) : (
                      <p className="col-9 p-0 m-0">
                        : {viewJd[0].min_exp}-{viewJd[0].max_exp} Years
                      </p>
                    )}
                  </div>
                  {/* <br></br> */}
                  {/* <br></br> */}
                  <div className="d-flex mt-3">
                    <p className="col-3 p-0 m-0">No.of Positions</p>
                    <p className="col-9 p-0 m-0">: {viewJd[0].no_position}</p>
                  </div>
                  <div className="d-flex mt-3">
                    <p className="col-3 p-0 m-0">Location</p>
                    <p className="col-9 p-0 m-0">: {viewJd[0].location}</p>
                  </div>
                  <div className="d-flex mt-3">
                    <p className="col-3 p-0 m-0">Type</p>
                    <p className="col-9 p-0 m-0">: {viewJd[0].type}</p>
                  </div>
                  {/* <b></> */}
                  <div className="d-flex mt-3">
                    <p className="col-3 p-0 m-0">Salary</p>
                    <p className="col-9 p-0 m-0">
                      : {viewJd[0].min_sal}-{viewJd[0].max_sal} CTC (LPA in
                      &#8377;)
                    </p>
                  </div>
                  <div className="d-flex mt-3">
                    <p className="col-3 p-0 m-0">Description</p>
                    <p className="px-0  mt-0 p-0 m-0">:&nbsp;</p>
                    <p className="col-9 p-0 m-0" style={{whiteSpace:"pre-wrap"}}>{viewJd[0].description}</p>
                  </div>
                  {viewJd[0].responsibilities && (
                    <div className="d-flex mt-3">
                      <p className="col-3 p-0 m-0">Responsibilities</p>
                      <p className="px-0  p-0 m-0">:&nbsp;</p>
                      <p className="col-9 p-0 m-0" style={{whiteSpace:"pre-wrap"}}>
                        {viewJd[0].responsibilities}
                      </p>
                    </div>
                  )}
                  <br></br>
                </CCardBody>
              </CCard>
            </CCol>
          )}
          {!apply && (
            <CCol
              xs="6"
              style={{ maxHeight: "73vh" }}
              className={"applyBoxAnimation info_details"}
            >
              <Apply
                value={{
                  setApply,
                  setJobCard,
                  setView,
                  SetCardDisplay,
                  setCardWidth,
                }}
              ></Apply>
            </CCol>
          )}
        </CRow>
      )}
    </CContainer>
  );
  //  : (
  //   <CContainer
  //     style={{ maxHeight: "100vh" }}
  //     className={showModal ? "info_details px-0" : "info_details"}
  //   >
  //     <div className="d-flex  justify-content-center align-items-center p-3 w-100">
  //       <img
  //         style={{ width: showModal ? "100%" : "50%" }}
  //         src={logo}
  //         alt=""
  //         className="mx-1"
  //       />
  //       {/* <h4
  //         style={{ textAlign: "center" }}
  //         className="logoName d-flex align-items-center mt-1 mx-1"
  //       >
  //         {!showModal ? (
  //           <h4 className=" logoName mt-1" style={{ color: "#0f77ea" }}>
  //             Mobigic Technologies Pvt Ltd.
  //           </h4>
  //         ) : (
  //           <h5 className=" logoName mt-1" style={{ color: "#0f77ea" }}>
  //             Mobigic Technologies Pvt Ltd.
  //           </h5>
  //         )}
  //       </h4> */}
  //     </div>
  //     <CRow
  //       className={"mx-auto pt-2 px-md-0  rounded-bottom "}
  //       style={{ width: "100%", maxHeight: "80%" }}
  //     >
  //       <CCol
  //         md={apply ? "12" : "7"}
  //         xs="12"
  //         className={" mx-auto d-block"}
  //         style={{ maxHeight: "73vh" }}
  //       >
  //         {viewJd[0] && (
  //           <CCard
  //             className={"shadow jobBoxAnimation"}
  //             sm="12"
  //             style={{
  //               height: "100%",
  //               maxHeight: "100%",
  //               borderRadius: "10px",
  //             }}
  //           >
  //             <CCardHeader
  //               style={{
  //                 borderTopLeftRadius: "10px",
  //                 borderTopRightRadius: "10px",
  //               }}
  //               className={"bg-white d-flex justify-content-between"}
  //             >
  //               <p className="h4 text-dark">{viewJd[0].title}</p>

  //               {apply && (
  //                 <Button
  //                   onClick={() => {
  //                     setJdId(viewJd[0].id);
  //                     setC_JD_ID(viewJd[0].custome_jd_id);
  //                     setDesig(viewJd[0].title);
  //                     // history.push({
  //                     //   pathname: "/openings/apply",
  //                     // });
  //                     setApply(false);
  //                   }}
  //                   className={"btn primary-btn btn-sm font-weight-bold"}
  //                 >
  //                   Apply
  //                 </Button>
  //               )}
  //             </CCardHeader>
  //             <CCardBody style={{ overflowY: "scroll", maxHeight: "100%" }}>
  //               <div className="d-flex p-0 m-0">
  //                 <p className="col-3 mt-2 p-0 ">Mandatory skill </p>
  //                 <p
  //                   className="m-0 d-flex col-9 p-0 "
  //                   style={{
  //                     whiteSpace: "nowrap",
  //                     textOverflow: "ellipsis",
  //                     alignItems: "flex-start",
  //                     alignContent: "flex-start",
  //                     minWidth: "0",
  //                     flexWrap: "wrap",
  //                     // overflowY: "scroll",
  //                   }}
  //                 >
  //                   <p className="px-0 pt-1 mt-1 p-0 m-0">:&nbsp;</p>
  //                   {viewJd[0].skills.map((obj, index) => {
  //                     return (
  //                       <p
  //                         key={index}
  //                         // className="p-0 m-0"
  //                         className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1 p-0 m-0"
  //                         // className="rounded bg-info text-white px-1 pt-1 ml-1 mt-1"
  //                       >
  //                         {/* {index == 0 ? <>:&nbsp;</> : <>&nbsp;&nbsp;</>} */}
  //                         {obj.value}{" "}
  //                         {/* {index < viewJd[0].skills.length - 1 && " ,"} */}
  //                       </p>
  //                     );
  //                   })}{" "}
  //                 </p>
  //               </div>

  //               {/* <p className="d-flex">
  //                   Mandatory skill :&nbsp;{" "}
  //                   {viewJd[0].skills.map((obj, index) => {
  //                     return (
  //                       <p key={index} className="m-0">
  //                         {obj.value}
  //                         {""}
  //                         {index != viewJd[0].skills.length - 1 && ", "}&nbsp;
  //                       </p>
  //                     );
  //                   })}
  //                 </p> */}
  //               {/* <br></br> */}
  //               {/* <p>
  //                   Experience : {viewJd[0].min_exp}-{viewJd[0].max_exp} Years
  //                 </p> */}
  //               <div className="d-flex">
  //                 <p className="col-3 p-0 m-0">Experience </p>
  //                 {viewJd[0].min_exp == "0" && viewJd[0].max_exp == "0" ? (
  //                   <p className="col-9 p-0 m-0">: Fresher</p>
  //                 ) : (
  //                   <p className="col-9 p-0 m-0">
  //                     : {viewJd[0].min_exp}-{viewJd[0].max_exp} Years
  //                   </p>
  //                 )}
  //               </div>
  //               {/* <br></br> */}
  //               {/* <br></br> */}
  //               <div className="d-flex">
  //                 <p className="col-3 p-0 m-0">No.of Positions</p>
  //                 <p className="col-9 p-0 m-0">: {viewJd[0].no_position}</p>
  //               </div>
  //               <div className="d-flex">
  //                 <p className="col-3 p-0 m-0">Location</p>
  //                 <p className="col-9 p-0 m-0">: {viewJd[0].location}</p>
  //               </div>
  //               <div className="d-flex">
  //                 <p className="col-3 p-0 m-0">Type</p>
  //                 <p className="col-9 p-0 m-0">: {viewJd[0].type}</p>
  //               </div>
  //               {/* <b></> */}
  //               <div className="d-flex">
  //                 <p className="col-3 p-0 m-0">Salary</p>
  //                 <p className="col-9 p-0 m-0">
  //                   : {viewJd[0].min_sal}-{viewJd[0].max_sal} CTC (LPA in
  //                   &#8377;)
  //                 </p>
  //               </div>
  //               <br></br>
  //               <p className="font-weight-bold">Job Description</p>

  //               <p style={{ whiteSpace: "pre-wrap" }}>
  //                 {viewJd[0].description}
  //               </p>
  //               {/* <p>
  //                   Closing on :{" "}
  //                   {moment(viewJd[0].close_date).format("DD-MMM-YYYY")}
  //                 </p> */}
  //             </CCardBody>
  //           </CCard>
  //         )}
  //       </CCol>
  //       {!apply && (
  //         <CCol
  //           xs="5"
  //           style={{ maxHeight: "73vh" }}
  //           className={"applyBoxAnimation info_details "}
  //         >
  //           <Apply></Apply>
  //         </CCol>
  //       )}
  //     </CRow>
  //   </CContainer>
  // );
}
